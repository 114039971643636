import React from 'react';

import Location from './Location';
import QandA from './QandA';
import PageAccordion from '../elements/PageAccordion';
import useTranslation from '../hooks/useTranslation';
import Wishes from './Wishes';
import { homeStrings } from '../strings';

function Home() {
    const strings = useTranslation(homeStrings);
    const pages = [
        {
            key: 'see-you-there',
            title: strings.seeYouThere,
            content: strings.seeYouThereContent
        },
        {
            key: 'location',
            title: strings.location,
            content: <Location />
        },
        {
            key: 'qa',
            title: strings.qanda,
            content: <QandA />
        },
        {
            key: 'wishlist',
            title: strings.wishes,
            content: <Wishes />
        },
        {
            key: 'music-request',
            title: strings.musicRequest,
            content: <>
                {strings.songRequestIntro}
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScs07MToAjVzSFD-jQ92WFtbJZ34KA0vIC-WIpq9GpRFM9cew/viewform?embedded=true" width="100%" height="380" frameborder="0" marginheight="0" marginwidth="0" title="Ønsk en sang">Loading…</iframe>
                {strings.songRequestOutro}
            </>
        }
    ];
    return (
        <>
            <section>
                {strings.intro}
            </section>
            <PageAccordion pages={pages} />
        </>
    );
}

export default {
    routeProps: {
        exact: true,
        path: '/'
    },
    component: Home,
    name: 'Home'
};