import React, { useEffect, useState } from "react";

import useTranslation from "../hooks/useTranslation";
import { wishesStrings } from "../strings";
import { shuffle } from "../util";

function Wishes(props) {
    const [wishes, setWishes] = useState([]);
    useEffect(() => {
        let ignore = false;
        setWishes([]);
        fetch('/wishes.json')
            .then(response => response.json())
            .then(json => {
                if(!ignore) {
                    setWishes(json);
                }
            });
        return () => {
            ignore = true;
        };
    }, []);
    const strings = useTranslation(wishesStrings);
    shuffle(wishes);
    const wishesContent = useTranslation(wishes);
    return <>
        {strings.intro}
        <ul id="wishes">
            {wishes.map((value, index) => (<li key={index}>
                {wishesContent[index]}
                {value.links?.map(link => <a key={link} href={link} target="_blank" rel="noopener noreferrer">Link</a>)}
            </li>))}
        </ul>
    </>;
}

export default Wishes;