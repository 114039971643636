import React from "react";

function shuffle(array) {
    let index = array.length;
    while(index !== 0) {
        let randIndex = Math.floor(Math.random() * index);
        index--;
        [array[index], array[randIndex]] = [array[randIndex], array[index]];
    }
}

function Emoji(props) {
    const {icon, label} = props;
    return (
        <span className="emoji" role="img" label={label}>{icon}</span>
    );
}

export {shuffle, Emoji};