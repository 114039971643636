import React from "react";

const headerStrings = {
    saturday: {
        da: 'Lørdag',
        en: 'Saturday',
        lol: 'Llamaturday'
    }
};

const homeStrings = {
    intro: {
        da: <>
            <p>Vi skal giftes! Og det skal naturligvis fejres i godt selskab. Derfor vil det glæde os at se dig/jer den 29. marts, hvor vi inviterer på glade stunder, lækker mad og fest til den lyse morgen.</p>
            <p>På denne side har vi samlet (forhåbentlig) alle de vigtigste oplysninger.</p>
        </>,
        en: <>
            <p>We are getting married! And of course we want to celebrate this in good company. That is why we are excited to see you on March 29th, where we are planning for happy times, delicious food, and a party until dawn.</p>
            <p>On this page we have (hopefully) gathered all the most important information.</p>
        </>,
        lol: <>
            <p>We’re getting <em>hitched</em>! And of course, we’re going to <em>spice</em> things up and celebrate in the best company! We’d be <em>over the moon</em> (and maybe a little <em>llama-zed</em>) if you could join us on March 29th, for a day filled with <em>joyful bites, delicious moments</em>, and a party that’ll keep you dancing till the early <em>morning coffee</em>!</p>
            <p>On this page, we’ve <em>served up</em> (hopefully) all the important <em>ingredients</em>.</p>
        </>
    },
    seeYouThere: {
        da: 'Ses vi?',
        en: 'See you there?',
        lol: 'Llama see you there?'
    },
    seeYouThereContent: {
        da: <>
            <p>Send os en bekræftelse via mail på <a href="mailto:bryllup@tineralph.dk">bryllup@tineralph.dk</a>. Her bedes du skrive:</p>
            <ul>
                <li>Antal gæster</li>
                <li>Har du/I brug for overnatning?</li>
                <li>Har du/I brug for transport (til kirke og/eller fra kirke til fest)?</li>
                <li>Evt. allergier eller madpræferencer</li>
            </ul>
            <p><strong>Svar udbedes senest 26. januar 2025.</strong></p>
        </>,
        en: <>
            <p>Send us a confirmation via email at <a href="mailto:bryllup@tineralph.dk">bryllup@tineralph.dk</a>. Please write:</p>
            <ul>
                <li>Number of guests</li>
                <li>Do you need accommodation?</li>
                <li>Do you need transportation (to church and/or from church to party)?</li>
                <li>Any allergies or food preferences</li>
            </ul>
            <p><strong>Responses are requested no later than January 26, 2025.</strong></p>
        </>,
        lol: <>
            <p>Send us a <em>llama-zing</em> confirmation via email at <a href="mailto:bryllup@tineralph.dk">bryllup@tineralph.dk</a>. Please include the following <em>ingredients</em>:</p>
            <ul>
                <li>How many <em>appetizers</em> (guests) will be joining us?</li>
                <li>Do you/your party need <em>a bed to loaf</em> (accommodation)?</li>
                <li>Do you/your party need <em>a ride to get your feet in the dip</em> (transport to the church and/or from the church to the party)?</li>
                <li>Any <em>spicy</em> allergies or <em>flavorful</em> food preferences?</li>
            </ul>
            <p><strong>We kindly request your <em>saucy</em> reply by January 26, 2025.</strong></p>
        </>
    },
    location: {
        da: 'Tid og sted',
        en: 'Time and place',
        lol: 'Time to ketchup and place to be!'
    },
    qanda: {
        da: 'Spørgsmål og svar',
        en: 'Q&A',
        lol: 'Bite-sized details'
    },
    wishes: {
        da: 'Ønskeliste',
        en: 'Wishlist',
        lol: 'What’s cooking on our wishlist?'
    },
    musicRequest: {
        da: 'Ønsk en sang',
        en: 'Request a song',
        lol: 'Let’s taco ‘bout your song request!'
    },
    songRequestIntro: {
        da: <>
            <p>Vi prøver at imødekomme så mange musikalske ønsker som muligt, men vi kan ikke love alt bliver spillet.</p>
        </>,
        en: <>
            <p>We will try to accommodate as many musical requests as possible, but we can't promise that everything will be played.</p>
        </>,
        lol: <>
            <p>We’ll do our best to <em>cater to as many musical tastes as possible</em>, though we can’t promise every song will make the playlist.</p>  
        </>
    },
    songRequestOutro: {
        da: <>
            <p>TAK for dit bidrag! Glemte du noget? Gå endelig tilbage og send flere ønsker.</p>
        </>,
        en: <>
            <p>Thanks for your contribution! Did you forget something? Please go back and send more requests.</p>
        </>,
        lol: <>
            <p>Thanks for your <em>pitch-perfect</em> contribution! Forgot something? Don’t be afraid to <em>turn up the volume</em> and send in more requests!</p>  
        </>
    },
};

const locationStrings = {
    locationCeremony: {
        da: <>
            Vielsen finder sted i <strong>Kasted Kirke</strong>, <strong>Kasted Byvej 6C, 8200 Aarhus N</strong>,
        </>,
        en: <>
            The wedding ceremony will take place in <strong>Kasted Church</strong>, <strong>Kasted Byvej 6C, 8200 Aarhus N</strong>,
        </>,
        lol: <>
            The wedding ceremony will take place at <strong>Kasted Church</strong>, <strong>Kasted Byvej 6C, 8200 Aarhus N</strong>,
        </>
    },
    locationParty: {
        da: <>
            hvorefter reception og fest finder sted på <strong>Lynet</strong>, <strong>Haraldslundvej 11, 8382 Hinnerup</strong>.
        </>,
        en: <>
            followed by a reception and party at <strong>Lynet</strong>, <strong>Haraldslundvej 11, 8382 Hinnerup</strong>. 
        </>,
        lol: <>
            followed by a reception and party at <strong>Lynet</strong>, <strong>Haraldslundvej 11, 8382 Hinnerup</strong>. Get ready to <em>wool-gather</em> your joy at the church and then <em>light up</em> your evening at Lynet—it’s going to be <em>llama-tastic</em> and <em>brimming with flavor</em>!
        </>
    },
    scheduleDisclaimer: {
        da: "Her følger en grov tidsplan for dagen med forbehold for mindre ændringer:",
        en: "A rough schedule follows (may be subject to minor changes):",
        lol: <>Here’s a rough <em>recipe</em> for the day, subject to <em>slightly spicy</em> changes</>
    },
    weddingCeremony: {
        da: <>Vielse <small>Starter på slaget – venligst ankom lidt før</small></>,
        en: <>Wedding ceremony <small>Starts on the dot – please arrive a bit earlier</small></>,
        lol: <>Wedding ceremony <small>Starts <em>on the dot</em> – please arrive a little <em>before the dough rises</em></small></>
    },
    reception: {
        da: <>Reception</>,
        en: <>Reception</>,
        lol: <>Reception</>
    },
    dinner: {
        da: "Middag",
        en: "Dinner",
        lol: "Dinner"
    },
    party: {
        da: "Fest til den lyse morgen",
        en: "Party all night",
        lol: <>Party till the sun rises and we’re still <em>bacon</em> at it!</>
    },
};

const qaStrings = {
    intro: {
        da: 'Forneden har vi prøvet at samle svar på nogle forskellige praktiske spørgsmål. Hvis vi har glemt noget, så kontakt os endelig.',
        en: <>We have tried to gather answers to some practical questions below. If we’ve forgotten anything, don’t hesitate to reach out.</>,
        lol: <>We have tried to gather answers to some practical questions below. If we’ve forgotten anything, don’t hesitate to reach out to us—we’ll be here, <em>stirring up</em> answers!</>
    }
};

const qaQuestions = [
    {
        q: {
            da: 'Er der mulighed for overnatning?',
            en: 'Is it possible to stay the night after the party?',
            lol: 'Is it possible to stay the night after the party?'
        },
        a: {
            da: <>
                <p>Vi vil rigtig gerne give jer mulighed for at overnatte, så I kan deltage i festen uden at tænke på hjemrejse sent på aftenen/natten.</p>
                <p>Et dobbeltværelse vil koste cirka 500 kr. Den nærmere pris melder vi tilbage, når vi har overblik over antal overnattende. Indtil da er reservation af værelse ikke bindende.</p>
                <p>Indtjekning vil blive håndteret ved ankomst, og udtjekning kan finde sted indtil middagstid om søndagen. Der bliver serveret en let morgenmad til de overnattende gæster.</p>
                <p><strong>NB:</strong> Da der kun findes et vist antal værelser, kan det blive nødvendigt at lave ekstra opredninger og samle 4 gæster på et dobbeltværelse. Hvis I er åbne for at dele værelse med andre, må I gerne skrive det i jeres svar på invitationen. Vi samler jer selvfølgelig kun med folk I kender og bekræfter detaljerne med jer alle på forhånd.</p>
            </>,
            en: <>
                <p>We would love to give you the option of staying overnight, so you can join the party without having to worry about a trip home late at night.</p>
                <p>A double room will cost approximately 500 DKK. We will get back with an exact price when we have an overview of the number of guests staying overnight. Until then, room reservations are not binding.</p>
                <p>Check-in is handled upon arrival, and check-out can take place until noon on Sunday. A light breakfast will be served to overnight guests.</p>
                <p><strong>Note:</strong> As we only have a limited number of rooms, it may be necessary to add extra beds and place 4 persons in a double room. If you're open to sharing a room, please let us know when giving your RSVP. You will of course only share a room with someone you know, and we will confirm the details beforehand.</p>
            </>,
            lol: <>
                <p><em>Llama tell you—absolutely!</em> We encourage you to stay overnight so you don’t have to <em>grill</em> yourself thinking about the late-night journey home.</p>
                <p>A double room will cost approximately 500 DKK. We’ll confirm the exact price once we have a full <em>spread</em> of how many guests are staying. Until then, room reservations aren’t binding—so no need to feel <em>toast-ed</em>.</p>
                <p>Check-in will be handled upon arrival, and check-out can happen up until noon on Sunday. A light breakfast will be served for our overnight guests to keep you <em>fuelled and frothy</em>!</p>
                <p><strong>Spicy detail:</strong>Since there are only a limited number of rooms, it may be necessary to <em>stir things up</em> with extra beds and fit four guests in a double room. If you’re open to <em>sharing your slice of space</em> with others, please mention it in your RSVP. Don’t worry, we’ll only <em>pair you up</em> with people you know (or someone we want you to <em>have a taste of</em>) and will confirm all the <em>juicy details</em> with everyone beforehand.</p>
            </>
        }
    },
    {
        q: {
            da: 'Må jeg afholde en tale eller et festligt indslag?',
            en: 'Can I give a speech or plan a game/festive activity?'
        },
        a: {
            da: <>
                <p>Meget gerne! Kontakt venligst Jeppe Andersen Helbo på SMS (27 62 47 60) for at koordinere nærmere detaljer.</p>
                <p>Deadline 14. marts.</p>
                <p>Vi ser gerne, at indslagene bliver delt ud over reception og middag. Hvis I foretrækker det ene frem for det andet, så fortæl det gerne til Jeppe.</p>
            </>,
            en: <>
                <p>You are very welcome! Please contact Jeppe Andersen Helbo by SMS (27 62 47 60) to coordinate further details.</p>
                <p>Deadline March 14th.</p>
                <p>We would like to spread any entries out between the reception and dinner. If you prefer one over the other, please let Jeppe know.</p>
            </>,
            lol: <>
                <p><em>Absolutely—lettuce celebrate your creativity!</em> Please contact Jeppe Andersen Helbo via SMS (27 62 47 60) to coordinate the juicy details.</p>
                <p>Deadline: March 14th.</p>
                <p>We’d love for your <em>brilliant bites of fun</em> to be sprinkled throughout the reception and dinner. If you have a preference for one over the other, please let Jeppe know—he’s ready to <em>whisk</em> your ideas into the perfect plan!</p>
            </>
        }
    },
    {
        q: {
            da: 'Er mine børn inviteret?',
            en: 'Are my kids invited?',
            lol: 'Are my kids invited?',
        },
        a: {
            da: <p>Vi drømmer om at holde en rigtig "voksenfest" med alt hvad dertil hører, så vi håber I vil få jeres skønne unger passet lige denne aften. Hvis I slet ikke kan få det til at hænge sammen, skal I selvfølgelig ikke blive væk af den grund – kontakt os gerne, og vi finder en løsning.</p>,
            en: <p>We plan to host a real "grown-up party" with all that this includes, so we hope you will get someone else to take care of your lovely kids this evening. If you can’t make this work, of course we don’t want you to stay away for that reason – contact us and we’ll find a solution.</p>,
            lol: <p>We’re dreaming of hosting a proper <em>“grown-up feast”</em> with all the trimmings, so we hope you’ll find someone to <em>babysit your little nuggets</em> for this special evening. However, if it’s just not possible to make it work, please don’t let that keep you from joining the <em>main course</em>! Reach out to us, and we’ll <em>cook up</em> a solution together.</p>
        }
    },
    {
        q: {
            da: 'Hvordan kommer jeg til og fra vielse og fest?',
            en: 'How do I get to and from ceremony and party?',
            lol: 'How do I get to and from ceremony and party?',
        },
        a: {
            da: <>
                <p>Vi ved det godt, vi har valgt at holde arrangementet langt ude på landet – og endda med et stykke vej fra vielse til fest.</p>
                <p>Det er naturligvis nemmest at komme frem og tilbage i egen bil. Men har I ikke mulighed for det, vil vi gerne hjælpe med at arrangere transport. Skriv det blot til os, når I bekræfter jeres deltagelse, og så finder vi en løsning.</p>
            </>,
            en: <>
                <p>We know that we have chosen to hold the event far out in the countryside – and even with a bit of a drive from the wedding to the party.</p>
                <p>It is of course easiest to get there and back in your own car. But if you do not have the opportunity to do so, we will be happy to help arrange transport. Just let us know when you confirm your participation, and we will find a solution.</p>
            </>,
            lol: <>
                <p>We know, we’ve <em>planted the seeds</em> of this celebration way out in the countryside—and there’s even a bit of a journey from the ceremony to the party.</p>
                <p>The easiest way to get around is in your own car, but if that’s not an option, we’re happy to <em>steer</em> you in the right direction by helping arrange transport. Just <em>drop us a line</em> when confirming your attendance, and we’ll <em>drive</em> a solution home!</p>
            </>
        }
    },
    {
        q: {
            da: 'Er der en dresscode?',
            en: 'Is there a dresscode?',
            lol: 'Is there a dresscode?'
        },
        a: {
            da: <>
                <p>Lad gerne sweatpants og hoodies blive hjemme (eller vente til søndag morgen). Ellers anbefaler vi noget tøj, I kan danse i!</p>
            </>,
            en: <>
                <p>Please leave the sweatpants and hoodies at home (or save them for Sunday morning). Aside from that, we recommend wearing something suitable for the dancefloor!</p>
            </>,
            lol: <>
                <p>Please leave the sweatpants and hoodies at home (or save them for a <em>post-party pancake brunch</em> on Sunday morning). Aside from that, we recommend wearing something you can really <em>cut a rug</em> in—because this dance floor will be <em>sizzling</em>!</p>
            </>
            
        }
    }
];

const wishesStrings = {
    intro: {
        da: <>
            <p>Vi elsker at blive overrasket og vil sætte pris på alle jeres gode påfund! Men hvis I er lige så uopfindsomme som Ralph, eller blot mangler lidt inspiration, har vi samlet nogle idéer.</p>
            <p>Listen kan også findes på <a href="https://onskeskyen.dk/s/du35vr" target="_blank" rel="noopener noreferrer">Ønskeskyen</a>, hvis du vil holde styr på hvad andre planlægger at give.</p>
        </>,
        en: <>
            <p>We love to be surprised! But if you are as unimaginative as Ralph, or just need a little inspiration, we have collected some ideas.</p>
            <p>The list can also be found on <a href="https://onskeskyen.dk/s/du35vr" target="_blank" rel="noopener noreferrer">Ønskeskyen</a> if you want to keep track of what others are planning to give.</p>
        </>,
        lol: <>
            <p>We love being surprised! But if you’re feeling as <em>uninventive as Ralph</em> (or just need a little <em>flavorful inspiration</em>), we’ve gathered some ideas to get you cooking.</p>  
            <p>You can also find the list on <a href="https://onskeskyen.dk/s/du35vr" target="_blank" rel="noopener noreferrer">Ønskeskyen</a>. It’s <em>loaded with gift-spiration</em>!</p>  
        </>
    }
};

export {
    headerStrings,
    homeStrings,
    locationStrings,
    qaStrings,
    qaQuestions,
    wishesStrings
};